<template>
    <div style="display: flex; gap: 0.3rem; justify-content: center; align-items: center;">
        <svg width="20" height="20" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6875 25.3125H18.3125V15.25H15.6875V25.3125ZM17 12.2549C17.4005 12.2549 17.7362 12.1194 18.0071 11.8484C18.2781 11.5775 18.4136 11.2418 18.4136 10.8413C18.4136 10.4409 18.2781 10.1051 18.0071 9.83419C17.7362 9.56352 17.4005 9.42819 17 9.42819C16.5995 9.42819 16.2638 9.56352 15.9929 9.83419C15.7219 10.1051 15.5864 10.4409 15.5864 10.8413C15.5864 11.2418 15.7219 11.5775 15.9929 11.8484C16.2638 12.1194 16.5995 12.2549 17 12.2549ZM17.0031 33.625C14.7036 33.625 12.5422 33.1887 10.5189 32.316C8.49558 31.4433 6.73567 30.259 5.23912 28.7631C3.74258 27.2671 2.55769 25.5079 1.68444 23.4855C0.811479 21.4631 0.375 19.3023 0.375 17.0031C0.375 14.7036 0.811333 12.5422 1.684 10.5189C2.55667 8.49558 3.74098 6.73567 5.23694 5.23912C6.7329 3.74258 8.49208 2.55769 10.5145 1.68444C12.5369 0.811479 14.6977 0.375 16.9969 0.375C19.2964 0.375 21.4578 0.811333 23.4811 1.684C25.5044 2.55667 27.2643 3.74098 28.7609 5.23694C30.2574 6.7329 31.4423 8.49208 32.3156 10.5145C33.1885 12.5369 33.625 14.6977 33.625 16.9969C33.625 19.2964 33.1887 21.4578 32.316 23.4811C31.4433 25.5044 30.259 27.2643 28.7631 28.7609C27.2671 30.2574 25.5079 31.4423 23.4855 32.3156C21.4631 33.1885 19.3023 33.625 17.0031 33.625ZM17 31C20.9083 31 24.2188 29.6437 26.9312 26.9312C29.6437 24.2188 31 20.9083 31 17C31 13.0917 29.6437 9.78125 26.9312 7.06875C24.2188 4.35625 20.9083 3 17 3C13.0917 3 9.78125 4.35625 7.06875 7.06875C4.35625 9.78125 3 13.0917 3 17C3 20.9083 4.35625 24.2188 7.06875 26.9312C9.78125 29.6437 13.0917 31 17 31Z" fill="#E8EAED"/>
        </svg>
        {{$t("More Details")}}
    </div>
</template>
<script>
export default {
    name:'InfoButton'
}
</script>
<style scoped lang="scss">

</style>